/* in your CSS or SCSS file */

.feedbackCell {
  white-space: pre-wrap; /* preserve whitespace and allow wrapping */
  max-height: none; /* remove maximum height constraints */
  overflow: visible; /* make content visible outside box */
}

/* You might also need to target the row if the cell style isn't sufficient */
.dataGrid .MuiDataGrid-row .MuiDataGrid-cell {
  max-height: none;
}
